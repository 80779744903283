<template>
  <div class="main_profile_wrapper">
    <!-- Start Patterns -->
    <div class="patterns_wrapper">
      <h2 class="main_title">Your Learning Patterns</h2>
      <div>
        <div class="wrapper_for_pattern">
          <div class="main_pattern_card" v-for="(item, i) in patterns" :key="i">
            <div
              class="part_one"
              :style="`background-color : ${getProgressColor(item.name)}`"
            >
              <div>
                <span class="pattern_name">{{ item.name_short }}</span>
              </div>
              <div class="numbers_wrapper">
                <span class="number">{{ item.number }}</span>
                <span class="number_key">{{ item.number_key }}</span>
              </div>
              <div class="d-flex justify-content-center ">
                <img
                  v-if="item.visible == true"
                  @click="hideCardDetails(i)"
                  role="button"
                  src="../../../assets/new-theme/profile/chevron-down.png"
                  alt=""
                />
                <img
                  v-if="item.visible == false"
                  @click="hideCardDetails(i)"
                  role="button"
                  src="../../../assets/new-theme/profile/chevron-down-under.png"
                  alt=""
                />
              </div>
            </div>
            <div class="part_two" v-if="item.visible">
              <div>
                <span class="pattern_name">{{ item.second_title }}</span>
              </div>
              <ul class="list_items">
                <li
                  class="item_in_list mt-1"
                  v-for="(pat, index) in item.items"
                  :key="index"
                >
                  {{ pat }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Patterns -->

    <!-- Table And Data -->
    <div class="main_table">
      <div>
        <h2 class="main_title">In School Tasks</h2>
        <p class="mb-0 text">
          Manage and edit your tasks or add new ones.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TasksView",
  data() {
    return {
      patterns: [
        {
          name: "Sequence",
          name_short: "Sequence (S)",
          number: "-",
          number_key: "-",
          second_title: "Sequential Learning Style",
          visible: true,
          items: [
            "Organizes life",
            "Makes Lists",
            "Lives by schedules",
            "Makes extensive plans"
          ]
        },
        {
          name: "Precision",
          name_short: "Precision (P)",
          number: "-",
          number_key: "-",
          second_title: "Precision Learning Style",
          visible: true,
          items: [
            "Relishes information",
            "Seeks facts",
            "Must be right",
            "Provides detailed responses"
          ]
        },
        {
          name: "Technical Reasoning",
          name_short: "Technical Reasoning (T)",
          number: "-",
          number_key: "-",
          second_title: "Technical Learning Style",
          visible: true,
          items: [
            "Solves problems",
            "Uses few words",
            "Looks for relevance and practicality",
            "Works alone"
          ]
        },
        {
          name: "Confluence",
          name_short: "Confluence (C)",
          number: "-",
          number_key: "-",
          second_title: "Confluence Learning Style",
          visible: true,
          items: [
            "Takes risks",
            "Sees the big picture",
            "Embraces innovation",
            "Initiates change"
          ]
        }
      ]
    };
  },
  computed: {
    mainUserId() {
      return this.$route.params.id ? this.$route.params.id : this.auth().id;
    },
    userScore() {
      return this.$store.getters["user/score"];
    },
    userData() {
      return this.$store.state.user.user;
    },
    image() {
      if (this.userData.image) {
        return `${process.env.VUE_APP_BASE_URL}/users/photo/${this.userData.image}`;
      }
      return "/static/img/lml-swerl.jpg";
    }
  },
  methods: {
    // Get User Data And Set User Score

    // Use First:35 - 25
    // As Needed:24 - 18
    // Avoid:17 - 7
    getMainUserData() {
      this.$store.dispatch("user/user", this.mainUserId).then(() => {
        // Set Pattern for the user scoring
        this.patterns.forEach(pattern => {
          if (pattern.name == "Sequence") {
            pattern.number = this.userScore?.sequence;
            // Start Pattern range
            if (
              this.userScore?.sequence >= 25 &&
              this.userScore.sequence <= 35
            ) {
              pattern.number_key = "Use First";
            } else if (
              this.userScore?.sequence >= 7 &&
              this.userScore.sequence <= 17
            ) {
              pattern.number_key = "Avoid";
            } else if (
              this.userScore?.sequence >= 18 &&
              this.userScore.sequence <= 24
            ) {
              pattern.number_key = "As Needed";
            }
            // End Pattern range
          }
          if (pattern.name == "Precision") {
            pattern.number = this.userScore?.precision;
            // Start Pattern range
            if (
              this.userScore?.precision >= 25 &&
              this.userScore.precision <= 35
            ) {
              pattern.number_key = "Use First";
            } else if (
              this.userScore?.precision >= 7 &&
              this.userScore.precision <= 17
            ) {
              pattern.number_key = "Avoid";
            } else if (
              this.userScore?.precision >= 18 &&
              this.userScore.precision <= 24
            ) {
              pattern.number_key = "As Needed";
            }
            // End Pattern range
          }
          if (pattern.name == "Technical Reasoning") {
            pattern.number = this.userScore?.technical_reasoning;
            // Start Pattern range
            if (
              this.userScore?.technical_reasoning >= 25 &&
              this.userScore.technical_reasoning <= 35
            ) {
              pattern.number_key = "Use First";
            } else if (
              this.userScore?.technical_reasoning >= 7 &&
              this.userScore.technical_reasoning <= 17
            ) {
              pattern.number_key = "Avoid";
            } else if (
              this.userScore?.technical_reasoning >= 18 &&
              this.userScore.technical_reasoning <= 24
            ) {
              pattern.number_key = "As Needed";
            }
            // End Pattern range
          }
          if (pattern.name == "Confluence") {
            pattern.number = this.userScore?.confluence;
            // Start Pattern range
            if (
              this.userScore?.confluence >= 25 &&
              this.userScore.confluence <= 35
            ) {
              pattern.number_key = "Use First";
            } else if (
              this.userScore?.confluence >= 7 &&
              this.userScore.confluence <= 17
            ) {
              pattern.number_key = "Avoid";
            } else if (
              this.userScore?.confluence >= 18 &&
              this.userScore.confluence <= 24
            ) {
              pattern.number_key = "As Needed";
            }
            // End Pattern range
          }
        });
        // Set the Selected User attribute
        this.selected = this.userData.selected_attributes;
      });
    },
    getProgressColor(key) {
      switch (key) {
        case "Precision":
          return "#3c3";
        case "Technical Reasoning":
          return "#f93";
        case "Confluence":
          return "#c36";
        case "Sequence":
          return "#39c";
      }
    },
    hideCardDetails(index) {
      this.patterns[index].visible = !this.patterns[index].visible;
    }
  },
  mounted() {
    this.getMainUserData();
  }
};
</script>

<style lang="scss" scoped>
.text {
  color: #667085;
  font-weight: 400;
  font-size: 16px;
}
.main_profile_wrapper {
  background: #fff;
  width: 100%;
  max-width: 100%;
  padding: 0 30px 50px;
}
.main_title {
  color: #101828;
  font-weight: 600;
  font-size: 20px;
  &.white_text {
    color: #fff;
  }
}
.patterns_wrapper {
  .wrapper_for_pattern {
    display: flex;
    justify-content: space-between;
    gap: 1.5%;
  }
  .main_pattern_card {
    border-radius: 8px;
    width: calc(94% / 4);

    .part_one {
      padding: 15px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      .pattern_name {
        font-weight: 500;
        font-size: 17px;
        color: #fff;
        margin-bottom: 15px;
        display: block;
      }
      .numbers_wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .number {
          font-weight: 500;
          font-size: 48px;
          color: #fff;
        }
        .number_key {
          font-weight: 500;
          font-size: 17px;
          color: #fff;
        }
      }
    }
    .part_two {
      padding: 15px;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      box-shadow: 0px 4px 6px -2px #10182808, 0px 12px 16px -4px #10182814;
      height: 200px;
      .pattern_name {
        font-weight: 500;
        font-size: 17px;
        color: #344054;
      }
      .list_items {
        margin-bottom: 0 !important;
        margin-left: 0;
        padding-left: 17px;
      }
      .item_in_list {
        font-weight: 400;
        font-size: 16px;
        color: #667085;
      }
    }
  }
}

.main_table {
  margin-top: 30px;
  border-radius: 8px;
  border: 1px solid #eaecf0;
  padding: 14px;
}
</style>
